import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SURVEY_STORE } from "../constants/constant";
import { SurveyType } from "src/types";

const initialSurveyState = {
  currentRouteIndex: 0,
  latestRouteIndex: 0,
};

export const useSurveyStore = create(
  devtools(
    persist<SurveyType>(
      (set) => ({
        ...initialSurveyState,
        setCurrentRouteIndex: (index: number, latestRouteIndex: number) => {
          set(() => ({
            currentRouteIndex: index,
          }));
          if (index > latestRouteIndex) {
            set(() => ({
              latestRouteIndex: index,
            }));
          }
        },
        clearSurvey: () => {
          set(() => initialSurveyState);
        },
      }),
      {
        name: SURVEY_STORE,
      }
    )
  )
);
