import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { CheckInType, CheckinTypeEnum } from "../types/types";
import { CHECKIN_STORE } from "../constants/constant";

const initialCheckInState = {
  avbEvent: undefined,
  bavEvent: undefined,
  avbYear: undefined,
  bavYear: undefined,
  checkInContent: undefined,
  checkInType: CheckinTypeEnum.FREELANCER,
};

export const useCheckInStore = create(
  devtools(
    persist<CheckInType>(
      (set) => ({
        ...initialCheckInState,
        setCheckInData: (data: any) => {
          try {
            set(() => ({
              avbEvent: data.avbEvent ?? undefined,
              bavEvent: data.bavEvent ?? undefined,
              avbYear: data.avbYear ?? undefined,
              bavYear: data.bavYear ?? undefined,
              bavTitle: data.bavTitle ?? undefined,
              avbTitle: data.avbTitle ?? undefined,
              checkInType: data.checkInType,
            }));
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setCheckInContent: (data: any) => {
          try {
            set(() => ({
              checkInContent: {
                avbTitle: data.avbTitle,
                bavTitle: data.bavTitle,
              },
            }));
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        clearCheckInData: async () => {
          set(() => initialCheckInState);
        },
      }),
      {
        name: CHECKIN_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
