import axios from "axios";
import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { usePartnerStore } from "./partner";
import { SimpleSatDataType, SimpleSatStateType } from "src/types";
import { SIMPLE_SAT_STORE } from "src/constants";
import { useAuthStore } from "./authentication";
import { getEnvironment } from "@utils/getEnvironment";
import { uniqBy } from "lodash";
import { getSurveyUrl } from "@utils/getSurveyUrl";

const initialSimpleSatState = {
  data: {
    answers: [],
    ticket: {
      id: undefined,
      external_id: undefined,
      subject: undefined,
    },
  },
  loading: false,
};

export const useSimpleSatStore = create(
  devtools(
    persist<SimpleSatStateType>(
      (set) => ({
        ...initialSimpleSatState,
        createResponse: async (
          surveyId: number,
          questionId: number,
          surveyData: any,
          customData?: { fieldType: string; fieldValue: string }
        ) => {
          try {
            set(() => ({ loading: true }));

            const partnerData = usePartnerStore.getState();
            const userData = useAuthStore.getState();
            await axios.post<SimpleSatDataType>(
              getSurveyUrl(),
              {
                survey_id: surveyId,
                question_id: questionId,
                tags: [
                  partnerData.name,
                  getEnvironment(),
                  `UserID : ${userData?.user?.id}`,
                  customData
                    ? `${customData.fieldType}: ${customData.fieldValue}`
                    : undefined,
                ],
                answers: uniqBy(
                  surveyData?.answers,
                  (answer: any) => answer?.question_id
                ),
                ticket: surveyData?.ticket,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            set(() => ({
              data: {
                answers: surveyData?.answers,
                ticket: surveyData?.ticket,
              },
            }));
            return true;
          } catch (err: any) {
            const error =
              err?.message ||
              err?.data?.message ||
              "Something went wrong. Please try again later.";
            throw error;
          } finally {
            set(() => ({ loading: false }));
          }
        },
        clear: () => {
          set(() => initialSimpleSatState);
        },
      }),
      {
        name: SIMPLE_SAT_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
