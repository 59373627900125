import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { UserChoiceType, UserChoicesType } from "../types/types";
import { USER_CHOICES_STORE } from "../constants/constant";

const initialUserChoicesState = {
  userChoices: [],
};

export const useUserChoicesStore = create(
  devtools(
    persist<UserChoicesType>(
      (set) => ({
        ...initialUserChoicesState,
        setUserChoice: (data: UserChoiceType) => {
          try {
            set((state) => ({
              userChoices: [
                data,
                ...state.userChoices.filter(
                  (choices) => choices.systemName !== data.systemName
                ),
              ],
            }));
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        clearChoices: () => {
          set(() => initialUserChoicesState);
        },
      }),
      {
        name: USER_CHOICES_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
